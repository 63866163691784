const PERMISSION = {
  READ: 1,
  CREATE: 2,
  EDIT: 4,
  DELETE: 8,
  READ_ALL: 16,
};

export const TRAININGS_PERMISSION = {
  ...PERMISSION,
  SHOW_MEMBERS: 32,
};

export const EVENTS_PERMISSION = {
  ...PERMISSION,
  ENROLL: 32,
  ASISTENCE: 64,
  APPROVE: 128,
};

export const REPRESENTATIVE_PERMISSION = {
  ...PERMISSION,
  BOX: 32,
  TRAIN: 64,
  ID: 128,
};

export const VIATIC_PERMISSION = {
  ...PERMISSION,
  AUTHORIZED: 32,
  DENIED: 64,
  AUDIT: 128,
  INCIDENT: 256,
};

export const NOMINA_PERMISSION = {
  ...PERMISSION,
  AUTHORIZED: 32,
  DENIED: 64,
  PAY: 128,
};

function permisoToSpanish(permiso) {
  switch (permiso.value) {
    case PERMISSION.READ:
      return "VER";
    case PERMISSION.CREATE:
      return "CREAR";
    case PERMISSION.EDIT:
      return "EDITAR";
    case PERMISSION.DELETE:
      return "ELIMINAR";
    case PERMISSION.READ_ALL:
      return "VER TODO";
    default:
      return permiso.label;
  }
}

export { permisoToSpanish };

export default PERMISSION;
