import React from "react";
import { getEmpty, getModelByIdAuth } from "../constants/request";
import firebase from "../services/firebase";
import useGet from "./useGet";

const AppContext = React.createContext();

export function AppProvider(props) {
  const [userSession, setUserSession] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [userRequest, setUserRequest] = React.useState(getEmpty);
  const [userAuth] = useGet(userRequest);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function onAuthStateChanged(user) {
      if (user) {
        const req = getModelByIdAuth(
          "auth",
          user.uid,
          "permiso,distritosAuthDf,permisoExtra"
        );
        setUserRequest(req);
      } else {
        setUserRequest(getEmpty);
      }
      setUserSession(user);
      setIsLoading(false);
    });
  }, []);

  const memData = React.useMemo(() => {
    return [userSession, isLoading, userAuth];
  }, [userSession, isLoading, userAuth]);

  return <AppContext.Provider value={memData} {...props} />;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}
