import React from "react";
import { useLocation } from "react-router-dom";
import PERMISSION from "../constants/permission";
import { useApp } from "./useApp";

const PermissionsContext = React.createContext();

export function PermissionsProvider(props) {
  const [_, isLoading, userAuth] = useApp();
  const [permissions, setPermissions] = React.useState(null);

  const checkPermission = React.useCallback(
    (toCheck) => {
      if (permissions) {
        const { pathname } = window.location;
        let path = pathname.split("/").filter((e) => e !== "");
        let res = false;
        if (path.length > 1) {
          const cpy = [...path];
          path = path[0];
          let last = cpy[cpy.length - 1];
          switch (last) {
            case "nuevo":
              return (
                (permissions[path] & PERMISSION.CREATE) === PERMISSION.CREATE
              );
            case "editar":
              return (permissions[path] & PERMISSION.EDIT) === PERMISSION.EDIT;
          }
        }
        const perm = permissions[path];
        res = (perm & PERMISSION.READ) === PERMISSION.READ;
        return res;
      }
      return false;
      // window.location.replace("/");
    },
    [permissions]
  );

  React.useEffect(() => {
    if (!isLoading && userAuth) {
      let perm = { ...userAuth[0].permiso };
      perm["dia-d"] = perm.diaD;
      delete perm.diaD;
      // delete perm.admin;
      // console.log(perm);
      setPermissions(perm);
    }
  }, [isLoading, userAuth]);

  const memData = React.useMemo(() => {
    return { permissions, checkPermission };
  }, [permissions, checkPermission]);

  return <PermissionsContext.Provider value={memData} {...props} />;
}

export function usePermissions() {
  const context = React.useContext(PermissionsContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}
