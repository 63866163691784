import { createMuiTheme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ad2624",
    },
  },
});

export default MuiTheme;
