import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useModelTable } from "../../hooks/useModelTable";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";

const Index = ({
  tituloCampo,
  inputValue,
  onChange,
  field,
  error,
  campoError,
  clearInput,
  ...props
}) => {
  const [
    modelTable,
    modelTableLoading,
    modelTableError,
    setBuscar,
  ] = useModelTable();

  const [openEnter, setOpenEnter] = React.useState(false);
  const [input, setInput] = React.useState("");

  React.useEffect(() => {
    let mounted = true;
    if (input) {
      if (mounted) {
        setBuscar(input);
      }
    }
    return () => (mounted = false);
  }, [input, setBuscar]);

  const onKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault(); // Ensure it is only this code that runs
      setOpenEnter(true);
      setBuscar(input);
    }
  };

  const buscarClick = () => {
    setOpenEnter(true);
    setBuscar(input);
  };

  React.useEffect(() => {
    setInput("");
  }, [clearInput]);


  return (
    <Grid container>
      <Grid item xs={11}>
        <Autocomplete
          open={openEnter}
          loading={modelTableLoading}
          freeSolo
          autoComplete
          inputValue={input}
          fullWidth
          onChange={(event, value) => {
            onChange(value);

            if (value) {
              if (value.id) {
                setOpenEnter(false);
              }
            }
          }}
          options={modelTableLoading ? [] : modelTable || []}
          getOptionLabel={(model) => (model[field] ? model[field] : input)}
          onInputCapture={(e) => {
            setInput(e.target.value);
            onChange();
          }}
          onInputChange={(event, value) => {
            setInput(value);
            if (value === "") {
              setOpenEnter(true);
              setBuscar(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              onFocus={() => {
                setOpenEnter(true);
              }}
              onClick={() => {
                setOpenEnter(true);
              }}
              error={error === campoError && error}
              onBlur={() => {
                setOpenEnter(false);
              }}
              value={input}
              onKeyDown={onKeyPress}
              label={tituloCampo}
              {...params}
            />
          )}
          {...props}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          style={{ cursor: "pointer", marginTop: 20 }}
          onClick={buscarClick}
          size="small"
        >
          <SearchIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Index;
