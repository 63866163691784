const getEmpty = {
  url: null,
  params: null,
};

const getModelById = (model, id, expand = null) => ({
  url: `/df/${model}`,
  params: {
    id,
    expand,
  },
});

const getModelById3 = (model, id, expand = null) => ({
  url: `/df/${model}/index2`,
  params: {
    id,
    expand,
  },
});

const getModelByClaveElectoral = (model, claveElectoral, expand = null) => ({
  url: `/df/${model}/buscar-por-ine`,
  params: {
    claveElectoral,
    expand,
  },
});

const getModelById2 = (model, id = "", expand = null) => ({
  url: `/df/${model}`,
  params: {
    id,
    expand,
  },
});

const getModelByIdAuth = (model, uid, expand = "") => ({
  url: `/df/${model}`,
  params: {
    uid,
    expand,
  },
});

const getModelByIdCasilla = (model, idRepresentante, expand = null) => ({
  url: `/df/${model}`,
  params: {
    idRepresentante,
    expand,
  },
});

const getModelByIdCapacitacionRepresentante = (
  model,
  idCapacitacion,
  idSimpatizante,
  expand = null
) => ({
  url: `/df/${model}`,
  params: {
    idCapacitacion,
    idSimpatizante,
    expand,
  },
});

const getModelByIdCasillaRepresentante = (
  model,
  expand = null,
  idCasilla = "",
  pagina = 0
) => ({
  url: `/df/${model}/todos`,
  params: {
    expand,
    idCasilla,
    pagina,
  },
});

// table requests
const getModels = (model, expand = "", nombre = "", pagina = 0) => {
  let req = {
    url: `/df/${model}`,
    params: { limite: 20, expand, nombre, ordenar: "uid.desc" },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (pagina !== 0) {
    req.params.pagina = pagina;
  }
  return req;
};

const getModels2 = ({
  model,
  params = {},
  expand = "",
  nombre = "",
  pagina = 0,
}) => {
  let req = {
    url: `/df/${model}`,
    params,
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (pagina !== 0) {
    req.params.pagina = pagina;
  }
  return req;
};

const getModels3 = ({
  model,
  params = {},
  expand = "",
  nombre = "",
  idEstado = "",
  pagina = 0,
}) => {
  let req = {
    url: `/df/${model}`,
    params,
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (idEstado && idEstado !== "") {
    req.params.idEstado = idEstado;
  }
  if (pagina !== 0) {
    req.params.pagina = pagina;
  }
  return req;
};

const getModelsCasilla = (
  model,
  expand = "",
  casilla = "",
  idDistrito = ""
) => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, casilla, idDistrito },
});

const getModelCapacitacionParticipanteById = (
  model,
  expand = "",
  idCapacitacionEvento = "",
  pagina = 0
) => ({
  url: `/df/${model}/representantes`,
  params: { limite: 20, expand, idCapacitacionEvento, pagina },
});

const getModelsMunicipioEstado = (
  model,
  expand = "",
  nombre = "",
  idEstado = ""
) => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, nombre, idEstado },
});

const getModelsDistrito = (model, expand = "", nombre = "", tipo = "") => ({
  url: `/df/${model}`,
  params: { limite: -1, ordenar: "nombre.asc", expand, nombre, tipo },
});

const getModelsGrupo = (model, expand = "") => ({
  url: `/df/${model}`,
  params: { limite: 20, ordenar: "nombre.asc", expand },
});

const getModelsDistrito2 = (model, expand = "", uid = "") => ({
  url: `/df/${model}/distritos`,
  params: { limite: 20, ordenar: "nombre.asc", expand, uid },
});

const getModelsDistrito3 = (model, expand = "", idDistritoFederal = "") => ({
  url: `/df/${model}/distritos-locales`,
  params: { limite: 20, ordenar: "nombre.asc", expand, idDistritoFederal },
});

const getModelsDistrito4 = (model, expand = "", uid = "", nombre = "") => ({
  url: `/df/${model}/secciones`,
  params: { limite: 20, ordenar: "nombre.asc", expand, uid, nombre },
});

const getModelsSeccion = (model, expand = "", nombre = "", distrito = "") => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, nombre, distrito },
});

const getEstadosDropdown = {
  url: "/df/estado",
  params: {
    fields: "id,nombre",
    ordenar: "nombre.asc",
  },
};

const getDistritosDropdown = {
  url: "/df/distrito",
  params: {
    fields: "id,nombre,tipo",
    ordenar: "tipo",
  },
};

const getUsuario = (uid, expand = "") => ({
  url: "/df/auth",
  params: {
    uid,
    expand,
  },
});

const getPermisos = {
  url: "/df/permiso/nombres",
  params: {},
};

const getDistritosLocalesByDfDropdown = (idDistrito, tipo = "") => ({
  url: "/df/distrito",
  params: {
    ordenar: "nombre.asc",
    fields: "id,nombre,idDistrito",
    idDistrito,
    tipo,
  },
});

const getSeccionesDropdown = {
  url: "/df/seccion",
  params: {
    fields: "id,nombre",
    ordenar: "nombre.asc",
  },
};

const getMunicipiosDropdown = {
  url: "/df/municipio",
  params: {
    fields: "id,nombre",
    ordenar: "nombre.asc",
  },
};

const getCapacitacionesDropdown = {
  url: "/df/capacitacion",
  params: {
    fields: "id,nombre",
    ordenar: "nombre.asc",
  },
};

const getCapacitacionesFilterDropdown = (
  model,
  idSeccion,
  idCapacitacion,
  expand = ""
) => ({
  url: "/df/" + model,
  params: {
    ordenar: "id.asc",
    expand,
    fields: "id,idSeccion,fechaCapacitacion,asistencia",
    idSeccion,
    idCapacitacion,
  },
});

const getModelByEstado = (
  model,
  expand = "",
  nombre = "",
  clave = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, nombre, clave, pagina },
});

const getModelByTipoincidencia = (
  model,
  expand = "",
  incidencia = "",
  descripcion = "",
  clave = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, incidencia, descripcion, clave, pagina },
});

const getModelByCapacitacion = (
  model,
  expand = "",
  nombre = "",
  descripcion = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    nombre,
    descripcion,
    pagina,
  },
});

const getModelByCapacitacionRepresentante = (
  model,
  expand = "",
  nombre = "",
  descripcion = ""
) => ({
  url: `/df/${model}`,
  params: { limite: 20, ordenar: "id.desc", expand, nombre, descripcion },
});

const getModelByMunicipio = (
  model,
  expand = "",
  nombre = "",
  estado = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: { limite: 20, expand, nombre, estado, pagina },
});

const getModelByMunicipio2 = (
  model,
  expand = "",
  nombre = "",
  estado = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: { limite: -1, expand, nombre, estado, pagina },
});

const getModelByCasilla = (
  model,
  expand = "",
  casilla = "",
  nombreDistrito = "",
  nombreSeccion = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    casilla,
    nombreDistrito,
    nombreSeccion,
    pagina,
  },
});

const getModelByCasilla2 = (
  model,
  expand = "",
  casilla = "",
  todoCasilla = "",
  nombreDistrito = "",
  idDistritoFederal = "",
  nombreSeccion = "",
  sinMapa = false,
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    casilla,
    todoCasilla,
    nombreDistrito,
    idDistritoFederal,
    nombreSeccion,
    sinMapa,
    pagina,
  },
});

const getModelByRepresentante = (
  model,
  expand = "",
  nombreDistrito = "",
  nombreSeccion = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  clave = "",
  idDistrito = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    nombreDistrito,
    nombreSeccion,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    clave,
    idDistrito,
    pagina,
  },
});

const getModelsBySupervision = (
  model,
  expand = "",
  idDistritoFederal = "",
  idDistritoLocal = "",
  idSeccion,
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  claveElector = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idDistritoFederal,
    idDistritoLocal,
    idSeccion,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    claveElector,
    pagina,
  },
});

const getModelByRepresentanteByAprobado = (
  model,
  expand = "",
  nombreDistrito = "",
  nombreSeccion = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  clave = "",
  idDistrito = "",
  aprobado = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    nombreDistrito,
    nombreSeccion,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    clave,
    idDistrito,
    aprobado,
    pagina,
  },
});

const getModelByRepresentanteEvento = (
  model,
  expand = "",
  nombreDistrito = "",
  nombreSeccion = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  clave = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    nombreDistrito,
    nombreSeccion,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    clave,
    pagina,
  },
});

const getModelByCapacitacionParticipante = (
  model,
  expand = "",
  idCapacitacionEvento = "",
  nombreRepresentante = "",
  apellidoPRepresentante = "",
  apellidoMRepresentante = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idCapacitacionEvento,
    nombreRepresentante,
    apellidoPRepresentante,
    apellidoMRepresentante,
    pagina,
  },
});

const getModelByCapacitacionParticipanteV3 = (
  model,
  expand = "",
  idCoordinador = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idCoordinador,
    pagina,
  },
});

const getModelByCapacitacionParticipanteV2 = (
  model,
  expand = "",
  idCapacitacionEvento = "",
  asistencia = "TODOS",
  nombreRepresentante = "",
  apellidoPRepresentante = "",
  apellidoMRepresentante = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idCapacitacionEvento,
    asistencia,
    nombreRepresentante,
    apellidoPRepresentante,
    apellidoMRepresentante,
    pagina,
  },
});

const getModelByCapacitacionR = (
  model,
  expand = "",
  nombreCapacitacion = "",
  nombreSimpatizante = "",
  apellidoPSimpatizante = "",
  apellidoMSimpatizante = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    nombreCapacitacion,
    nombreSimpatizante,
    apellidoPSimpatizante,
    apellidoMSimpatizante,
    pagina,
  },
});

const getModelByCapacitacionR2 = (
  model,
  expand = "",
  idDistritoLocal = "",
  idSeccion = "",
  idCapacitacion = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idDistritoLocal,
    idSeccion,
    idCapacitacion,
    pagina,
  },
});

const getModelByCapacitacionR5 = (
  model,
  expand = "",
  idDistritoFederal = "",
  idDistritoLocal = "",
  idCapacitacion = "",
  aprobados = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    idDistritoFederal,
    idDistritoLocal,
    idCapacitacion,
    aprobados,
    pagina,
  },
});

const getModelByViaticosReporte = (
  model,
  expand = "",
  nombre = "",
  estatus = "",
  fechaInicio = "",
  fechaFin = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    nombre,
    estatus,
    fechaInicio,
    fechaFin,
    pagina,
  },
});

const getModelByIdCapacitacionParticipante = (
  model,
  expand = "",
  idCapacitacion = 0,
  pagina = 0
) => ({
  url: `/df/${model}/miembros`,
  params: {
    limite: 20,
    expand,
    idCapacitacion,
    pagina,
  },
});

const getModelByIdCapacitacionParticipanteFecha = (
  model,
  expand = "",
  idCapacitacion = 0,
  fechaInicio = "",
  fechaFin = "",
  pagina = 0
) => ({
  url: `/df/${model}/miembros`,
  params: {
    limite: 20,
    expand,
    idCapacitacion,
    fechaInicio,
    fechaFin,
    pagina,
  },
});

const getModelByViaticos = (
  model,
  expand = "",
  estatus = "",
  fechaInicio = "",
  fechaFin = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    estatus,
    fechaInicio,
    fechaFin,
    pagina,
    ordenar: "id.desc",
  },
});

const getModelByEvento = (
  model,
  expand = "",
  FechaEvento = "",
  fechaInicio = "",
  fechaFin = "",
  lugar = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    FechaEvento,
    fechaInicio,
    fechaFin,
    lugar,
    pagina,
  },
});

const getModelByEventoInstructor = (
  model,
  expand = "",
  FechaEvento = "",
  fechaInicio = "",
  fechaFin = "",
  lugar = "",
  idInstructor = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    FechaEvento,
    fechaInicio,
    fechaFin,
    lugar,
    idInstructor,
    pagina,
  },
});

const getModelByAvanceCasilla = (
  model,
  expand = "",
  idDistritoFederal = "",
  idDistritoLocal = "",
  idSeccion = "",
  order = "",
  pagina = 0
) => ({
  url: `/df/${model}/avances-casilla`,
  params: {
    limite: 20,
    expand,
    idDistritoFederal,
    idDistritoLocal,
    idSeccion,
    order,
    pagina,
  },
});

const getModelByTotalCasilla = (
  model,
  expand = "",
  idDistritoFederal = "",
  idDistritoLocal = "",
  idSeccion = "",
  pagina = 0
) => ({
  url: `/df/${model}/total-casilla`,
  params: {
    limite: 20,
    expand,
    idDistritoFederal,
    idDistritoLocal,
    idSeccion,
    pagina,
  },
});

const getModelByAuth2 = (
  model,
  expand = "",
  capacitador = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    expand,
    capacitador,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    pagina,
  },
});

const getModelByAuth = (
  model,
  expand = "",
  capacitador = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  claveElector = "",
  correo = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (capacitador && capacitador !== "") {
    req.params.capacitador = capacitador;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (apellidoPaterno && apellidoPaterno !== "") {
    req.params.apellidoPaterno = apellidoPaterno;
  }
  if (apellidoMaterno && apellidoMaterno !== "") {
    req.params.apellidoMaterno = apellidoMaterno;
  }
  if (claveElector && claveElector !== "") {
    req.params.claveElector = claveElector;
  }
  if (correo && correo !== "") {
    req.params.correo = correo;
  }
  return req;
};

const getModelByAuth3 = (
  model,
  expand = "",
  capacitador = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  claveElector = "",
  correo = "",
  permiso = "",
  idDistrito = "",
  idSeccion = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/index2`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (capacitador && capacitador !== "") {
    req.params.capacitador = capacitador;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (apellidoPaterno && apellidoPaterno !== "") {
    req.params.apellidoPaterno = apellidoPaterno;
  }
  if (apellidoMaterno && apellidoMaterno !== "") {
    req.params.apellidoMaterno = apellidoMaterno;
  }
  if (claveElector && claveElector !== "") {
    req.params.claveElector = claveElector;
  }
  if (correo && correo !== "") {
    req.params.correo = correo;
  }
  if (permiso && permiso !== "") {
    req.params.permiso = permiso;
  }
  if (idDistrito && idDistrito !== "") {
    req.params.idDistrito = idDistrito;
  }
  if (idSeccion && idSeccion !== "") {
    req.params.idSeccion = idSeccion;
  }
  return req;
};

const getModelByAuth9 = (
  model,
  expand = "",
  capacitador = "",
  nombre = "",
  apellidoPaterno = "",
  apellidoMaterno = "",
  claveElector = "",
  correo = "",
  permiso = "",
  idDistrito = "",
  idSeccion = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/index3`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (capacitador && capacitador !== "") {
    req.params.capacitador = capacitador;
  }
  if (nombre && nombre !== "") {
    req.params.nombre = nombre;
  }
  if (apellidoPaterno && apellidoPaterno !== "") {
    req.params.apellidoPaterno = apellidoPaterno;
  }
  if (apellidoMaterno && apellidoMaterno !== "") {
    req.params.apellidoMaterno = apellidoMaterno;
  }
  if (claveElector && claveElector !== "") {
    req.params.claveElector = claveElector;
  }
  if (correo && correo !== "") {
    req.params.correo = correo;
  }
  if (permiso && permiso !== "") {
    req.params.permiso = permiso;
  }
  if (idDistrito && idDistrito !== "") {
    req.params.idDistrito = idDistrito;
  }
  if (idSeccion && idSeccion !== "") {
    req.params.idSeccion = idSeccion;
  }
  return req;
};



const getModelByAuth4 = (
  model,
  expand = "",
  idDistrito = 0,
  idSeccion = 0,
  rol = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/index2`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (idDistrito && idDistrito > 0) {
    req.params.idDistrito = idDistrito;
  }

  if (idSeccion && idSeccion > 0) {
    req.params.idSeccion = idSeccion;
  }

  if (rol && rol !== "") {
    req.params.rol = rol;
  }
  return req;
};

const getModelByAuth5 = (
  model,
  expand = "",
  idDistrito = 0,
  idSeccion = 0,
  rol = "",
  idCreador = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/index2`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (idDistrito && idDistrito > 0) {
    req.params.idDistrito = idDistrito;
  }
  if (idSeccion && idSeccion > 0) {
    req.params.idSeccion = idSeccion;
  }
  if (rol && rol !== "") {
    req.params.rol = rol;
  }
  if (idCreador && idCreador !== "") {
    req.params.idCreador = idCreador;
  }
  return req;
};

const getModelByAuth6 = (
  model,
  expand = "",
  idDistrito = 0,
  rol = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/cuentas-por-distrito`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (idDistrito && idDistrito > 0) {
    req.params.idDistrito = idDistrito;
  }
  if (rol && rol !== "") {
    req.params.rol = rol;
  }
  return req;
};

const getModelByAuth7 = (
  model,
  expand = "",
  idDistrito = 0,
  rol = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/total`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (idDistrito && idDistrito > 0) {
    req.params.idDistrito = idDistrito;
  }
  if (rol && rol !== "") {
    req.params.rol = rol;
  }
  return req;
};

const getModelByBuscarAutocompletado = (model, expand = "", nombre = "") => ({
  url: `/df/${model}`,
  params: { limite: -1, ordenar: "tipo", expand, nombre },
});

const getModelByViatico = (model, estatus = "", pagina = 0) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    estatus,
    pagina,
    ordenar: "id.desc",
  },
});

const getModelByViaticoPendiente = (model) => ({
  url: `/df/${model}/pendientes`,
  params: {},
});

const getInpersonar = (model, uid) => ({
  url: `/df/${model}/inpersonar`,
  params: { uid },
});

const getModelByViaticoFecha = (
  model,
  expand = "",
  fechaInicio = "",
  fechaFin = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    fechaInicio,
    fechaFin,
    pagina,
  },
});

const getModelByNominaFecha = (
  model,
  expand = "",
  estatus = "",
  fechaInicio = "",
  fechaFin = "",
  fechaPagar = "",
  nombre = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    estatus,
    fechaInicio,
    fechaFin,
    fechaPagar,
    nombre,
    pagina,
  },
});

const getModelBySeccion = (
  model,
  expand = "",
  idDistrito = "",
  accion = "",
  pagina = 0
) => ({
  url: `/df/${model}/total-secciones`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    idDistrito,
    accion,
    pagina,
  },
});

const getModelBySecciones = (
  model,
  expand = "",
  idDistrito = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.asc",
    expand,
    idDistrito,
    pagina,
  },
});

const getModelByNominaListado = (model, expand = "", pagina = 0) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    pagina,
  },
});

const getModelByNominaListadoIdNomina = (
  model,
  expand = "",
  idNomina = "",
  nombre = "",
  correo = "",
  idDistritoLocal = "",
  cargo = "",
  clave = "",
  celular = "",
  pagina = 0
) => ({
  url: `/df/${model}`,
  params: {
    limite: 20,
    ordenar: "id.desc",
    expand,
    idNomina,
    nombre,
    correo,
    idDistritoLocal,
    cargo,
    clave,
    celular,
    pagina,
  },
});

const getModelByAuth10 = (
  model,
  expand = "",
  idDistritoFederal = 0,
  idDistritoLocal = 0,
  idSeccion = 0,
  rol = "",
  pagina = 0
) => {
  let req = {
    url: `/df/${model}/index2`,
    params: {
      limite: 20,
      pagina,
    },
  };
  if (expand && expand !== "") {
    req.params.expand = expand;
  }
  if (idDistritoFederal && idDistritoFederal > 0) {
    req.params.idDistritoFederal = idDistritoFederal;
  }
  if (idDistritoLocal && idDistritoLocal > 0) {
    req.params.idDistritoLocal = idDistritoLocal;
  }
  if (idSeccion && idSeccion > 0) {
    req.params.idSeccion = idSeccion;
  }
  if (rol && rol !== "") {
    req.params.rol = rol;
  }
  return req;
};

export {
  getEmpty,
  getEstadosDropdown,
  getDistritosDropdown,
  getMunicipiosDropdown,
  getSeccionesDropdown,
  getPermisos,
  getCapacitacionesDropdown,
  getModels2,
  getModelByRepresentanteByAprobado,
  getModels,
  getModelsCasilla,
  getModelByIdCasillaRepresentante,
  getModelByCapacitacionRepresentante,
  getModelsDistrito,
  getModelsSeccion,
  getModelById,
  getModelByIdAuth,
  getModelByEstado,
  getModelByTipoincidencia,
  getModelByCapacitacion,
  getModelByRepresentante,
  getModelByCapacitacionR,
  getModelByMunicipio,
  getModelByCasilla,
  getModelByBuscarAutocompletado,
  getDistritosLocalesByDfDropdown,
  getModelByIdCapacitacionRepresentante,
  getModelByCapacitacionParticipante,
  getUsuario,
  getModelByIdCasilla,
  getModelByEvento,
  getModelByAuth,
  getModelByRepresentanteEvento,
  getModelByIdCapacitacionParticipante,
  getModelByIdCapacitacionParticipanteFecha,
  getModelByCapacitacionParticipanteV2,
  getModelsMunicipioEstado,
  getModelCapacitacionParticipanteById,
  getModelsDistrito2,
  getModelsDistrito3,
  getModelsDistrito4,
  getModelByEventoInstructor,
  getModelsGrupo,
  getModelById2,
  getInpersonar,
  getCapacitacionesFilterDropdown,
  getModelByCapacitacionR2,
  getModelByClaveElectoral,
  getModelById3,
  getModelByAuth2,
  getModelByCasilla2,
  getModelByAuth3,
  getModelByViatico,
  getModelByViaticoFecha,
  getModelByViaticos,
  getModelByAuth4,
  getModelByAuth5,
  getModelByAuth6,
  getModelByAuth7,
  getModelByViaticoPendiente,
  getModelByNominaFecha,
  getModelByNominaListado,
  getModelByNominaListadoIdNomina,
  getModelBySeccion,
  getModelByCapacitacionParticipanteV3,
  getModelByAvanceCasilla,
  getModelByTotalCasilla,
  getModelByCapacitacionR5,
  getModelByViaticosReporte,
  getModelByMunicipio2,
  getModelByAuth9,
  getModelByAuth10,
  getModelBySecciones,
  getModelsBySupervision
};
