import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import { useAlert } from "./../hooks/useAlert";
import { Snackbar } from "@material-ui/core";

const Index = ({}) => {
  const { open, position, severity, message } = useAlert();
  return (
    <>
      <Snackbar open={open} anchorOrigin={position}>
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Index;
