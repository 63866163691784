import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import AsyncComponent from "../components/AsyncComponent";
import DashboardLayout from "../components/DashboardLayout";
import { usePermissions } from "../hooks/usePermissions";
import { createBrowserHistory } from "history";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { useAlert } from "../hooks/useAlert";
import Alert1 from "./alerts";

const Home = AsyncComponent(() => import("../views/Home"));
const Form = AsyncComponent(() => import("../views/Form"));
const Casillas = AsyncComponent(() => import("../views/casillas/index"));
const CasillaDetail = AsyncComponent(() => import("../views/casillas/detail"));
const Estados = AsyncComponent(() => import("../views/catalogs/Estados/index"));
const EstadoDetail = AsyncComponent(() =>
  import("../views/catalogs/Estados/detail")
);

const Tipoincidencia = AsyncComponent(() =>
  import("../views/catalogs/Tipoincidencia/index")
);
const TipoincidenciaDetail = AsyncComponent(() =>
  import("../views/catalogs/Tipoincidencia/detail")
);

const Profile = AsyncComponent(() => import("../views/profile/index"));
const ProfileDetail = AsyncComponent(() => import("../views/profile/detail"));

const Municipios = AsyncComponent(() =>
  import("../views/catalogs/Municipios/index")
);
const MunicipioDetail = AsyncComponent(() =>
  import("../views/catalogs/Municipios/detail")
);

const DiaDCasillas = AsyncComponent(() => import("../views/dia-d/casillas"));
const DiaDVotacion = AsyncComponent(() => import("../views/dia-d/votacion"));
const MonitoreoCD = AsyncComponent(() => import("../views/dia-d/monitoreoCD"));
const ImportacionCasillas = AsyncComponent(() =>
  import("../views/importaciones/casillas")
);
const ImportacionNominaListado = AsyncComponent(() =>
  import("../views/importaciones/nominaListadoImportacion")
);
const Capacitaciones = AsyncComponent(() =>
  import("../views/catalogs/Capacitaciones/index")
);
const CapacitacionDetail = AsyncComponent(() =>
  import("../views/catalogs/Capacitaciones/detail")
);
const Representantes = AsyncComponent(() =>
  import("../views/representantes/index")
);
const RepresentanteDetail = AsyncComponent(() =>
  import("../views/representantes/detail")
);
const RepresentanteId = AsyncComponent(() =>
  import("../views/representantes/card")
);
const CapacitacionRepresentante = AsyncComponent(() =>
  import("../views/capacitacionRepresentante/index")
);
const CapacitacionRepresentanteDetail = AsyncComponent(() =>
  import("../views/capacitacionRepresentante/detail")
);
const Eventos = AsyncComponent(() => import("../views/eventos/index"));
const EventoDetail = AsyncComponent(() => import("../views/eventos/detail"));

const Permisos = AsyncComponent(() => import("../views/permisos/index"));

const Sectores = AsyncComponent(() => import("../views/sectores"));
const SectoresDetail = AsyncComponent(() => import("../views/sectores/detail"));

const Grupos = AsyncComponent(() => import("../views/grupos"));
const GruposDetail = AsyncComponent(() => import("../views/grupos/detail"));

const Usuarios = AsyncComponent(() => import("../views/usuariosAuth"));
const UsuariosDetail = AsyncComponent(() =>
  import("../views/usuariosAuth/detail")
);

const Reportes = AsyncComponent(() =>
  import("../views/catalogs/Reportes/index")
);

const Viaticos = AsyncComponent(() => import("../views/viaticos"));
const ViaticosDetail = AsyncComponent(() => import("../views/viaticos/detail"));

const Nomina = AsyncComponent(() => import("../views/nomina"));
const NominaDetail = AsyncComponent(() => import("../views/nomina/detail"));
const Actas = AsyncComponent(() => import("../views/dia-d/actas"));
const ActasDetail = AsyncComponent(() => import("../views/dia-d/actasDetail"));
const SeccionDetail = AsyncComponent(() => import("../views/dia-d/seccionDetail"));
const Supervisiones = AsyncComponent(() => import("../views/dia-d/supervision"));

const privateHistory = createBrowserHistory();

const PrivateRoutes = () => {
  // const [permissions] = usePermissions();
  // const { open, position, severity, message } = useAlert();

  // const memoSnack = React.useMemo(
  //   () => (
  //     <Snackbar open={open} anchorOrigin={position}>
  //       <Alert severity={severity} elevation={6} variant="filled">
  //         {message}
  //       </Alert>
  //     </Snackbar>
  //   ),
  //   [open, position, severity, message]
  // );

  return (
    <Router history={privateHistory}>
      <DashboardLayout>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          {/* <PrivateRoute exact path="/form" component={Form} /> */}
          <PrivateRoute exact path="/estados" component={Estados} />
          <PrivateRoute exact path="/estados/nuevo" component={EstadoDetail} />
          <PrivateRoute
            exact
            path="/estado/editar/:id"
            component={(props) => <EstadoDetail {...props} />}
          />

          <PrivateRoute exact path="/dia-d/casillas" component={DiaDCasillas} />
          <PrivateRoute exact path="/dia-d/votacion" component={DiaDVotacion} />
          <PrivateRoute exact path="/dia-d/monitoreo" component={MonitoreoCD} />
          <PrivateRoute
            exact
            path="/tipoincidencias"
            component={Tipoincidencia}
          />
          <PrivateRoute
            exact
            path="/tipoincidencias/nuevo"
            component={TipoincidenciaDetail}
          />
          <PrivateRoute
            exact
            path="/tipoincidencias/editar/:id"
            component={(props) => <TipoincidenciaDetail {...props} />}
          />

          <PrivateRoute exact path="/perfil/:uid" component={Profile} />
          <PrivateRoute
            exact
            path="/perfil/editar/:uid"
            component={(props) => <ProfileDetail {...props} />}
          />

          <PrivateRoute exact path="/municipios" component={Municipios} />
          <PrivateRoute
            exact
            path="/municipios/nuevo"
            component={MunicipioDetail}
          />
          <PrivateRoute
            exact
            path="/municipios/editar/:id"
            component={(props) => <MunicipioDetail {...props} />}
          />
          <PrivateRoute exact path="/casillas" component={Casillas} />
          <PrivateRoute
            exact
            path="/casillas/nuevo"
            component={CasillaDetail}
          />
          <PrivateRoute
            exact
            path="/casillas/editar/:id"
            component={(props) => <CasillaDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/importaciones/casillas"
            component={ImportacionCasillas}
          />
          <PrivateRoute
            exact
            path="/importaciones/nominaListadoImportacion"
            component={ImportacionNominaListado}
          />
          <PrivateRoute exact path="/capacitacion" component={Capacitaciones} />
          <PrivateRoute
            exact
            path="/capacitacion/nuevo"
            component={CapacitacionDetail}
          />
          <PrivateRoute
            exact
            path="/capacitacion/editar/:id"
            component={(props) => <CapacitacionDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/representantes"
            component={Representantes}
          />
          <PrivateRoute
            exact
            path="/representantes/nuevo"
            component={RepresentanteDetail}
          />
          <PrivateRoute
            exact
            path="/representantes/editar/:id"
            component={(props) => <RepresentanteDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/representantes/id/:id"
            component={(props) => <RepresentanteId {...props} />}
          />
          <PrivateRoute
            exact
            path="/capacitacion-representante"
            component={CapacitacionRepresentante}
          />
          <PrivateRoute
            exact
            path="/capacitacion-representante/nuevo"
            component={CapacitacionRepresentanteDetail}
          />
          <PrivateRoute
            exact
            path="/capacitacion-representante/editar/:id"
            component={(props) => (
              <CapacitacionRepresentanteDetail {...props} />
            )}
          />
          <PrivateRoute exact path="/eventos" component={Eventos} />
          <PrivateRoute exact path="/eventos/nuevo" component={EventoDetail} />
          <PrivateRoute
            exact
            path="/eventos/editar/:id"
            component={(props) => <EventoDetail {...props} />}
          />
          <PrivateRoute exact path="/sectores" component={Sectores} />
          <PrivateRoute exact path="/sectores/:id" component={SectoresDetail} />
          <PrivateRoute exact path="/grupos" component={Grupos} />
          <PrivateRoute exact path="/grupos/:id" component={GruposDetail} />
          <PrivateRoute exact path="/usuarios" component={Usuarios} />
          <PrivateRoute
            exact
            path="/usuarios/nuevo"
            component={(props) => <UsuariosDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/usuarios/editar/:uid"
            component={(props) => <UsuariosDetail {...props} />}
          />
          <PrivateRoute exact path="/permisos" component={Permisos} />
          <PrivateRoute exact path="/reportes" component={Reportes} />

          <PrivateRoute exact path="/viaticos" component={Viaticos} />
          <PrivateRoute
            exact
            path="/viaticos/nuevo"
            component={ViaticosDetail}
          />
          <PrivateRoute
            exact
            path="/viaticos/editar/:id"
            component={(props) => <ViaticosDetail {...props} />}
          />
          <PrivateRoute exact path="/nominas" component={Nomina} />
          <PrivateRoute exact path="/actas" component={Actas} />
          <PrivateRoute exact path="/nominas/nuevo" component={NominaDetail} />
          <PrivateRoute
            exact
            path="/nominas/editar/:id"
            component={(props) => <NominaDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/actas/detalle/:id"
            component={(props) => <ActasDetail {...props} />}
          />
          <PrivateRoute
            exact
            path="/actas/detalle/seccion/:id"
            component={(props) => <SeccionDetail {...props} />}
          />
          <PrivateRoute exact path="/supervisiones" component={Supervisiones} />
        </Switch>
        <Alert1></Alert1>
      </DashboardLayout>
    </Router>
  );
};

export default PrivateRoutes;
