import React from "react";
import { httpService } from "../services";

const useGet = ({ url, params }) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [page, setPage] = React.useState(null);

  React.useEffect(() => {
    let cancelRequest = false;
    if (!url || !params) return;
    const get = async () => {
      try {
        if (cancelRequest) return;
        const data = await httpService.get(url, params);
        setData(data.resultado);
        setPage(data.paginacion);
      } catch (error) {
        if (cancelRequest) return;
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    get();
    return () => {
      cancelRequest = true;
    };
  }, [url, params]);

  return React.useMemo(() => {
    const refresh = async () => {
      try {
        //if (cancelRequest) return;
        setLoading(() => true);
        const data = await httpService.get(url, params);
        setData(data.resultado);
        setPage(data.paginacion);
      } catch (error) {
        //if (cancelRequest) return;
        setError(error);
      } finally {
        setLoading(() => false);
      }
    };
    return [data, loading, error, page, refresh];
  }, [data, loading, error, page, url, params]);
};

export default useGet;
