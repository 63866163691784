import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ListItemsView from "../components/ListItems";
import Badge from "@material-ui/core/Badge";
import Select from "../components/autocomplete/index";
import PersonIcon from "@material-ui/icons/Person";
import { useHistory, NavLink } from "react-router-dom";
import firebase from "../services/firebase";
import { useApp } from "../hooks/useApp";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  },
}));

export default function DashboardLayout(props) {
  const classes = useStyles();
  const [accountMenuOpen, setAccountMenuOpen] = React.useState(false);
  const theme = useTheme();
  const history = useHistory();
  const buttonRef = React.useRef(null);
  const [userSession, isLoading, userAuth] = useApp();

  const [open, setOpen] = React.useState(true);

  const accountMenuClicked = () => {
    setAccountMenuOpen(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = async () => {
    const key = buttonRef.current.getAttribute("key");
    // console.log(buttonRef.current);
    setAccountMenuOpen(false);
    switch (key) {
      case "profile":
        history.push(`/perfil/editar/${user.uid}`);
        break;
      case "config":
        history.push("/config");
        break;
      case "logout":
        logoutClicked();
        break;
      default:
        break;
    }
  };

  const logoutClicked = () => {
    firebase.auth().signOut();
    history.push("/");
  };

  var user = firebase.auth().currentUser;
  const goProfile = () => {
    handleClose();
    history.push(`/perfil/editar/${user.uid}`);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {open === true ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: !open,
              })}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            src="defensa-blanco.png"
            alt=""
            style={{ width: "10%", margin: "-20px", marginTop: "-11px" }}
          ></img>

          <Grid container justify="flex-end">
            <Typography style={{ color: "white", marginTop: "15px" }}>
              {userAuth &&
                userAuth[0] &&
                userAuth[0].nombre +
                  " " +
                  userAuth[0].apellidoPaterno +
                  " " +
                  userAuth[0].apellidoMaterno}
            </Typography>
            <IconButton
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={accountMenuClicked}
              ref={buttonRef}
            >
              <Badge color="secondary">
                <PersonIcon />
              </Badge>
            </IconButton>
          </Grid>

          <Menu
            id="profile-menu"
            anchorEl={buttonRef.current}
            keepMounted
            // onClick={goProfile}
            open={accountMenuOpen}
            onClose={handleClose}
          >
            <MenuItem onClick={goProfile}>Perfil</MenuItem>
            <MenuItem onClick={logoutClicked}>Cerrar sesión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <ListItemsView></ListItemsView>
      </Drawer>
      <main className={classes.content}>
        <Container className={classes.container} style={{ maxWidth: "100%" }}>
          {props.children}
        </Container>
      </main>
    </div>
  );
}
