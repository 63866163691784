import React from "react";
import {
  getModels,
  getModelsMunicipioEstado,
  getEmpty,
  getModelsSeccion,
  getModelsCasilla,
} from "../constants/request";
import useGet from "./useGet";

const ModelTableContext = React.createContext();

export function ModelTableProvider({
  model = null,
  expand = null,
  distrito = "",
  estado = "",
  ...props
}) {
  // estado interno del hook
  const [request, setRequest] = React.useState(getEmpty);
  const [data, loading, error] = useGet(request);
  const [buscar, setBuscar] = React.useState("");

  React.useEffect(() => {
    if (model == "seccion") {
      setRequest(getModelsSeccion(model, expand, buscar, distrito));
    } else {
      if (model == "casilla") {
        setRequest(getModelsCasilla(model, expand, buscar,distrito));
      } else {
        if (model == "municipio" && estado !== "") {
          setRequest(getModelsMunicipioEstado(model, expand, buscar, estado));
        } else {
          setRequest(getModels(model, expand, buscar));
        }
      }
    }
  }, [model, expand, buscar, distrito]);

  const memData = React.useMemo(() => {
    // props q exporta useMemo
    // if (!data) return [data, loading, error, setRequest];
    return [data, loading, error, setBuscar];
    // cuando cambie una de estas props, se vuelve a correr useMemo
  }, [data, loading, error]);

  return <ModelTableContext.Provider value={memData} {...props} />;
}

export function useModelTable() {
  const context = React.useContext(ModelTableContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: modelTable context not defined.";
  }
  return context;
}
