import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AsyncComponent from "../components/AsyncComponent";

const SignIn = AsyncComponent(() => import("../views/SignIn"));
const NotFound = AsyncComponent(() => import("../views/shared/NotFound"));

const PublicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={SignIn} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
