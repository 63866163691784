import firebase from "./firebase";

const baseUrl = "https://sire.miralo.xyz";
//const baseUrl = "http://localhost:8080";

const GET = "GET";
const POST = "POST";

const paramsToQuery = (params) =>
  Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

const headers = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const procesarRespuesta = (response) => {
  if (response.status >= 300) {
    return Promise.reject(response);
  }
  return response.json();
};

const httpService = {
  async _get(uri, params, config = {}) {
    const token = await firebase.auth().currentUser.getIdToken();
    const reqOptions = {
      method: GET,
      headers: headers(token),
      ...config,
    };
    return fetch(
      `${baseUrl}${uri}${params ? `?${paramsToQuery(params)}` : ""}`,
      reqOptions
    ).then(procesarRespuesta);
  },
  async _post(uri, body, config = {}) {
    const token = await firebase.auth().currentUser.getIdToken();
    const reqOptions = {
      method: POST,
      headers: headers(token),
      body: JSON.stringify(body),
      ...config,
    };
    return fetch(`${baseUrl}${uri}`, reqOptions).then(procesarRespuesta);
  },
  async get(url, params) {
    return this._get(url, params);
  },
  async post(url, body) {
    return this._post(url, body);
  },
};

export default httpService;
