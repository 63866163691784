import React from "react";
import { AppProvider, useApp } from "./hooks/useApp";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import MuiTheme from "./theme/MuiTheme";
import FullScreenLoader from "./components/FullScreenLoader";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { PermissionsProvider } from "./hooks/usePermissions";
import { AlertProvider } from "./hooks/useAlert";
import { NotificacionProvider } from "./hooks/useNotificacion";

const AppRouting = (props) => {
  const [user, loading] = useApp();

  if (loading) return <FullScreenLoader />;
  return user ? (
    <PermissionsProvider>
      <PrivateRoutes />
    </PermissionsProvider>
  ) : (
    <PublicRoutes />
  );
};

const App = () => {
  return (
    <AppProvider>
      <ThemeProvider theme={MuiTheme}>
        <CssBaseline />
        <AlertProvider>
          <NotificacionProvider>
            <AppRouting />
          </NotificacionProvider>
        </AlertProvider>
      </ThemeProvider>
    </AppProvider>
  );
};

export default App;
